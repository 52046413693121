<!-- OLD DESIGN -->
<!-- <div class="topbar"> -->

<!-- LOGO -->
<!-- <div class="topbar-left">
		<a class="logo">
			<span>
				<img src="assets/images/Ampliz-logo-black.png" alt="" />
			</span>
			<i>
				<img style="height: 30px" src="assets/images/Ampliz-logo-black.png" alt="" />
			</i>
		</a>
	</div> -->

<!-- <app-search></app-search> -->
<!-- <nav class="navbar-custom">
		<ul class="list-inline menu-left mb-0 d-block float-left d-sm-none">
			<li class="float-left">
				<button class="button-menu-mobile open-left waves-light waves-effect" (click)="toggleSideMenu()">
					<i class="mdi mdi-menu"></i>
				</button>
			</li>
		</ul>
		<app-search *ngIf="dataSet == 'healthcare'"></app-search> -->

<!-- <app-b2b-global-search *ngIf="dataSet == 'B2B'"></app-b2b-global-search> -->

<!-- <ul class="list-unstyled topbar-right-menu float-right mb-0"> -->
<!-- <li class="hide-phone">
                <a   *ngIf="showChrmBtn"  type="button" class="btn btn-primary ">{{chrome_button}}</a>{{showChrmBtn}}
            </li> -->
<!-- <li class="hide-phone"> -->
<!-- <a *ngIf="showBtn"  (click)= "openItem('pricing')" type="button" class="btn btn-primary ">{{button}}</a> -->
<!-- <span
					*ngIf="
						showBtn &&
						router.url != '/payment' &&
						router.url != '/editprofile' &&
						router.url != '/hcupgrade' &&
						router.url != '/mytab' &&
						!isSpecialityUser
					"
				>
					<a *ngIf="dataSet == 'B2B'" type="button" class="btn btn-yellow" (click)="openItem(dataSet)" type="">{{
						data
					}}</a>
					<a
						*ngIf="dataSet == 'healthcare'"
						type="button"
						class="btn btn-yellow"
						(click)="requestPricing()"
						type=""
						>{{ data }}</a
					>
				</span> -->
<!-- <span
          *ngIf="
            router.url != '/payment' &&
            router.url != '/editprofile' &&
            router.url != '/hcupgrade' &&
            router.url != '/mytab' &&
            router.url != '/lists' &&
            router.url != '/hospital' &&
            router.url != '/geneateapi' &&
            !router.url.includes('executiveOverview')&&
            !router.url.includes('physicianOverview')&&
            isSpecialityUser
          "
        >
          <a
            type="button"
            class="btn btn-yellow"
            (click)="requestSearch()"
            type=""
            >Request</a
          >
        </span> -->
<!-- </li> -->
<!-- <li class="daily-search-quota-container" *ngIf="dataSet === 'B2B'">
				<app-daily-search-quota
					[dailyCredit]="dailyCurrentCredits"
					[usedCredit]="dailyUsedCredits"
				></app-daily-search-quota>
			</li>
			<li class="dropdown notification-list">
				<a
					class="nav-link dropdown-toggle waves-effect waves-light nav-user"
					data-toggle="dropdown"
					href="#"
					role="button"
					aria-haspopup="false"
					aria-expanded="false"
				>
					<img src="/assets/images/users/Unknwn.png" alt="user" class="rounded-circle" />
					<span class="ml-1" style="z-index: 9999999">{{ user }} <i class="mdi mdi-chevron-down"></i> </span>
				</a>
				<div id="navbarDropdown" class="dropdown-menu dropdown-menu-right profile-dropdown"> -->
<!-- item-->
<!-- <a class="link dropdown-item notify-item" (click)="navigatePage('editprofile')">
						<i class="fa fa-gear"></i> <span> Edit Profile </span>
					</a>
					<a href="javascript:void(0);" (click)="logout()" class="dropdown-item notify-item">
						<i class="ti-power-off"></i> <span>Logout</span>
					</a>
				</div>
			</li>
		</ul>
	</nav> -->

<!-- <nav class="menu-nav navbar">
		<span>Dashboard</span>
		<span>Free Credits</span>
		<span>Subscription</span>
		<span>Physicians</span>
		<span>Hospital</span>
		<span>Executive</span>
		<span>Imaging</span>
		<span>LTC</span>
		<span>MCO</span>
		<span>NPI</span>
		<span>List</span>
		<span>Team Member</span>
		<span>Generate API Key</span>
	</nav>
</div> -->
<!-- <app-loader *ngIf="showLoader == true"></app-loader>
<app-success *ngIf="showSuccessMesssage"></app-success>
<app-error *ngIf="showError"></app-error> -->
<!-- OLD DESIGN ENDS -->

<!-- NEW DESIGN  -->

<div class="header-bar topbar row align-items-center" [class.custom-box-shadow]="subNav.length <= 0">
	<!-- LOGO -->
	<div class="col-1">
		<i>
			<img style="height: 30px" src="assets/images/Ampliz-logo-black.png" alt="" />
		</i>
	</div>
	<!-- LOGO ends -->

	<!-- Nav Items starts -->
	<div class="col-4 main-nav">
		<div class="menu-nav d-flex">
			<div class="dropdown" *ngFor="let menu of dataSet !== 'B2B' ? hcMenu : b2bMenu">
				<span class="dropbtn" [class.active]="menu.active" (click)="navigate(menu.url)">
					<i class="mr-2" [ngClass]="menu.icon"></i>{{ menu.name }}</span
				>

				<div class="dropdown-content" *ngIf="menu.sub.length > 0" [class.disable-drop-down]="menu.active">
					<div class="submenu-container">
						<ng-container *ngFor="let sub of menu.sub">
							<div class="submenu-item d-flex align-items-center" (click)="navigate(sub.url)">
								<div class="icon-container col-2" *ngIf="sub.icon !== ''">
									<i [ngClass]="sub.icon"></i>
								</div>

								<div class="col-8 d-flex flex-column">
									<span class="hover-underline-animation"> {{ sub.name }}</span>
									<span class="description">{{ sub.description }}</span>
								</div>
							</div>
						</ng-container>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- Nav Items Ends -->

	<!-- Upgrade button starts -->
	<div class="col upgrade-container d-flex justify-content-end">
		<span
			*ngIf="
				showBtn &&
				router.url != '/payment' &&
				router.url != '/editprofile' &&
				router.url != '/hcupgrade' &&
				router.url != '/mytab' &&
				!isSpecialityUser
			"
		>
			<a *ngIf="dataSet == 'B2B'" type="button" class="btn btn-yellow" (click)="openItem(dataSet, 'b2b')" type=""
				>{{ data }}</a
			>
			<a *ngIf="dataSet == 'healthcare'" type="button" class="btn btn-yellow" (click)="requestPricing()" type=""
				>{{ data }}</a
			>
		</span>
	</div>
	<!-- Upgrade button ends -->

	<!-- Action Container starts -->
	<div class="col action-container d-flex justify-content-end">
		<div class="search-container1" *ngIf="dataSet !== 'B2B'">
			<app-search></app-search>
		</div>
		<div class="b2b-quota-loader" *ngIf="dataSet === 'B2B'">
			<app-daily-search-quota
				[dailyCredit]="dailyCurrentCredits"
				[usedCredit]="dailyUsedCredits"
			></app-daily-search-quota>
		</div>
	</div>
	<!-- Action Container Ends -->

	<!-- Profile starts -->
	<div class="dropdown notification-list col-1 text-right">
		<span
			class="account-dropdown d-flex align-items-center justify-content-end dropdown-toggle waves-effect waves-light nav-user"
			data-toggle="dropdown"
		>
			<img src="/assets/images/users/Unknwn.png" alt="user" class="rounded-circle mr-1" />
			<span style="z-index: 999" class="mr-2">{{ firstName }} </span>
		</span>

		<div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu2">
			<a
				*ngIf="isAdmin"
				class="link dropdown-item notify-item d-flex align-items-center"
				(click)="navigate('team-member')"
			>
				<i class="mr-2 mdi mdi-account-multiple"></i> <span>Teams</span>
			</a>

			<a
				class="link dropdown-item notify-item d-flex align-items-center"
				*ngFor="let item of dataSet !== 'B2B' ? accountMenu : b2bAccountMenu"
				(click)="navigate(item.url)"
			>
				<i [ngClass]="item.icon" class="mr-2"></i> <span> {{ item.name }}</span>
			</a>
		</div>
	</div>
	<!-- Profile ends -->
	<!-- Main Nav ends -->

	<!-- Second Navigation Starts-->
	<div class="lower-header col-12" *ngIf="subNav.length > 0">
		<nav class="nav d-flex align-items-center">
			<span
				class="nav-link d-flex align-items-center"
				*ngFor="let item of subNav"
				[class.active-sub-nav]="item.active"
				(click)="navigate(item.url)"
			>
				<i
					[ngClass]="item.icon"
					class="mr-2"
					*ngIf="item.icon !== 'mat-ltc' || item.icon !== 'mat-imaging' || item.icon !== 'mco-svg'"
				></i>
				<!-- ALT ICONS -->
				<svg
					*ngIf="item.icon === 'mco-svg'"
					class="svg_icon mr-2"
					xmlns="http://www.w3.org/2000/svg"
					height="1em"
					viewBox="0 0 576 512"
				>
					<path
						d="M543.8 287.6c17 0 32-14 32-32.1c1-9-3-17-11-24L309.5 7c-6-5-14-7-21-7s-15 1-22 8L10 231.5c-7 7-10 15-10 24c0 18 14 32.1 32 32.1h32V448c0 35.3 28.7 64 64 64H448.5c35.5 0 64.2-28.8 64-64.3l-.7-160.2h32zM256 208c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v48h48c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H320v48c0 8.8-7.2 16-16 16H272c-8.8 0-16-7.2-16-16V320H208c-8.8 0-16-7.2-16-16V272c0-8.8 7.2-16 16-16h48V208z"
					/>
				</svg>
				<mat-icon *ngIf="item.icon === 'mat-imaging' || item.icon === 'mat-ltc'">
					{{ item.icon === 'mat-imaging' ? 'filter_frames' : 'accessible' }}
				</mat-icon>
				<!-- ALT ICONS END -->

				{{ item.name }}
			</span>
		</nav>
	</div>
	<!-- Second Navigation Ends-->
</div>
