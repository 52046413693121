export class SearchPhysicianModel {
	constructor(
		public specialityIncluded: Array<string> = [],
		public specialityExcluded: Array<string> = [],
		public physicianName: string = '',
		public npiNumber: Array<string> = [],
		public hospitalNameList: Array<string> = [],
		public cityList: Array<string> = [],
		public stateList: Array<string> = [],
		public offset: number = 0,
		public savedListOffset: number = 5,
		public limit: number = 5,
		public emailTypeIsp: string = '',
		public provider_Type: string = '',
		public leadWithEmail: boolean = false,
		public leadWithPhone: boolean = false,
		public searchType: string = '',
		public clientIp: string = '',
		public email_Score: any = 0,
		public experience: string[] = [],
		public languages: string[] = [],
		public age: string = '',
		public cptCodes: number[] = [],
		public hcpcsCodes: number[] = [],
		public speciality: any[] = [],
		public specialityGroup: any = '',
		public subSpeciality: any[] = [],
		public ageRangeList: any[] = [],
		public bsn: string = '',
		public abom: string = '',
		public abmsabps: string = '',
		public istelehealth: any = '',
		public firmtype: any = '',
		public totalclaimsrange: any = [],
		public productclassification: any = [],
		public ehrproductname: any = [],
		public classification: any = [],
		public zipcode: string[] = [],
		public radiusZipcodes: string[] = [],
		public residencyhospitals: any[] = [],
		public fellowships: any[] = [],
		public medicalschools: any[] = [],
		public undergraduate_state: any[] = [],
		public medicalschool_state: any[] = [],
		public residency_state: any[] = [],
		public fellowship_state: any[] = [],
		public licenses_state: any[] = []
	) {}

	public toJson(): object {
		const specility = this.speciality.map((item) => item.speciality);
		return {
			specialityIncluded: this.specialityIncluded,
			specialityExcluded: this.specialityExcluded,
			physicianName: this.physicianName,
			npiNumber: this.npiNumber,
			hospitalNameList: this.hospitalNameList,
			cityList: this.cityList.map((item: any) => item.city),
			stateList: this.stateList.map((item: any) => item.state),
			offset: this.offset,
			savedListOffset: this.savedListOffset,
			limit: this.limit,
			emailTypeIsp: this.emailTypeIsp,
			provider_Type: this.provider_Type,
			leadWithEmail: this.leadWithEmail,
			leadWithPhone: this.leadWithPhone,
			searchType: this.searchType,
			clientIp: this.clientIp,
			email_Score: this.email_Score,
			experience: this.experience,
			languages: this.languages,
			age: this.age,
			hcpcsCodes: this.hcpcsCodes,
			cptCodes: this.cptCodes,
			speciality: specility,
			specialityGroup: this.specialityGroup,
			subSpeciality: this.subSpeciality,
			ageRangeList: this.ageRangeList,
			bsn: this.bsn ? (this.bsn === 'RN without BSN' ? 'No' : 'Yes') : '',
			istelehealth: this.istelehealth ? 'Y' : '',
			firmtype: this.firmtype,
			totalclaimsrange: this.totalclaimsrange,
			productclassification: this.productclassification,
			ehrproductname: this.ehrproductname,
			classification: this.classification,
			abom: this.abom,
			abmsabps: this.abmsabps,
			zipcode: this.zipcode,
			radiusZipcodes: this.radiusZipcodes,
			residencyhospitals: this.residencyhospitals,
			fellowships: this.fellowships,
			medicalschools: this.medicalschools,
			undergraduate_state: this.undergraduate_state,
			medicalschool_state: this.medicalschool_state,
			residency_state: this.residency_state,
			fellowship_state: this.fellowship_state,
			licenses_state: this.licenses_state
		};
	}

	public fromJson(obj: any): SearchPhysicianModel {
		return new SearchPhysicianModel(
			obj.specialityIncluded,
			obj.specialityExcluded,
			obj.physicianName,
			obj.npiNumber,
			obj.hospitalNameList,
			obj.cityList,
			obj.stateList,
			obj.offset,
			obj.savedListOffset,
			obj.limit,
			obj.emailTypeIsp,
			obj.provider_Type,
			obj.leadWithEmail,
			obj.leadWithPhone,
			obj.searchType,
			obj.clientIp,
			obj.email_Score,
			obj.experience,
			obj.languages,
			obj.age,
			obj.cptCodes,
			obj.hcpcsCodes,
			obj.speciality,
			obj.specialityGroup,
			obj.subSpeciality,
			obj.ageRangeList
		);
	}
}
